import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Nav = _resolveComponent("Nav")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_Header, {
      cart: _ctx.allCart,
      likes: _ctx.allLikes,
      onShowMenu: _ctx.Home.showMenu
    }, null, 8, ["cart", "likes", "onShowMenu"]),
    _createVNode(_component_Nav, {
      show: _ctx.showMenu,
      onShowMenu: _ctx.Home.showMenu
    }, null, 8, ["show", "onShowMenu"]),
    (!_ctx.isHome)
      ? (_openBlock(), _createBlock(_component_Breadcrumb, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isRouterAlive)
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.route.fullPath
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Footer)
  ]))
}
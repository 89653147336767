import axios, { AxiosInstance } from 'axios';
import { ElMessage, ElLoading } from 'element-plus';
import i18n from '@/locales';
import router from '@/router';

const languages = {
  'zh-CN': 'Simplified Chinese',
  'zh-HK': 'Traditional Chinese',
  'en-US': 'English',
  'ja-JP': 'Japanese',
};

const showTip = (message: string, type: string = 'warning') => {
  ElMessage({
    message: message,
    // type: type,
    grouping: true,
  });
};
const toLogin = (): void => {
  router.push({ path: '/login' });
};

const codeHandle = (status: number | string, message: string): void => {
  loading.error();
  switch (status) {
    case 0:
      showTip(message || '登录过期，请重新登录', 'error');
      break;
    case 401:
      toLogin();
      break;
    case 402:
      // toLogin();
      break;
    case 403:
    case 'A0301':
      showTip(message || '登录过期，请重新登录', 'error');
      toLogin();
      break;
    case 404:
      showTip(message, 'error');
      break;
    case 502:
      showTip(message || '服务器异常', 'error');
      break;
    default:
      showTip(message || '其它异常', 'error');
  }
};

const noTips = [
  '/cbi-candidate-provider/verifyShortUrl',
  '/workflow-provider/oauth/login',
  '/workflow-provider/oauth/logout',
];

// 增加全局loading遮罩唯一，避免多次请求重复出现遮罩问题
const loading = {
  el: null as any,
  pending: {} as { [key: string]: any },
  get: (config: { [key: string]: any }, isReuest = false) => {
    let url = config.url;
    const data = typeof config.data === 'string' ? JSON.parse(config.data) : config.data;
    if (isReuest) {
      url = config.baseURL + config.url.substring(1, config.url.length);
    }
    return config.method === 'get'
      ? encodeURIComponent(url + JSON.stringify(config.params))
      : encodeURIComponent(config.url + JSON.stringify(data));
  },
  remove: (key: string, isRequest = false) => {
    if (loading.pending[key] && isRequest) {
      loading.pending[key]('cancel');
    }
    delete loading.pending[key];
  },
  show: () => {
    if (!loading.el) {
      loading.el = ElLoading.service({
        lock: true,
        text: '',
        spinner: '',
        background: 'rgba(0,0,0,.5)',
        fullscreen: true,
        customClass: 'mod-loading',
      });
    }
  },
  hide: () => {
    if (loading.el && Object.keys(loading.pending).length === 0) {
      loading.el?.close();
      loading.el = null;
    }
  },
  error: () => {
    loading.el?.close();
    loading.el = null;
    loading.pending = {};
  },
};

const CancelToken = axios.CancelToken;
const HTTP: AxiosInstance = axios.create({
  baseURL: '/',
  timeout: 30 * 1000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

HTTP.interceptors.request.use(
  (config) => {
    const requestData = loading.get(config, true);
    loading.remove(requestData, true);
    config.cancelToken = new CancelToken((cancel) => {
      loading.pending[requestData] = cancel;
    });
    //设置uuid
    const uuid = localStorage.getItem('uuid');
    if (uuid) {
      config.headers['uuid'] = uuid;
    }
    // 设置当前语言环境
    // config.headers['Content-Type'] = config.header['Content-Type']
    // config.headers['Accept-Language'] = i18n.global.locale.value;
    // config.headers['Access-Control-Allow-Origin'] = '*'
    // 请求头认证信息设置
    config.headers.Authorization = sessionStorage.getItem('userToken')
      ? `${sessionStorage.getItem('userToken')}`
      : '';
    config.headers['languageNameWeb'] = languages[i18n.global.locale.value];
    config.headers['Real-IP'] = sessionStorage.getItem('Real-IP');
    // config.headers['X-Real-IP'] = getSession('X-Real-IP') ? getSession('X-Real-IP') : '';
    // 全局loading
    // loading.show();
    return config;
  },
  (error: any) => {
    loading.error();
    Promise.reject(error);
  },
);

HTTP.interceptors.response.use(
  (response: { [key: string]: any }) => {
    // const requestData = loading.get(response.config);
    // loading.remove(requestData);
    // loading.hide();
    const isBolb = response.config.responseType === 'blob'; // 返回文件流
    if (isBolb) {
      if (response.data.type.includes('application/json')) {
        return new Promise((resolve, reject) => {
          try {
            const reader = new FileReader();
            reader.readAsText(response.data);
            reader.onload = async function (event) {
              resolve(JSON.parse(event.target?.result + ''));
            };
          } catch (e) {
            reject(e);
          }
        });
      } else {
        return response.data;
      }
    } else {
      if (response.status === 200) {
        return response.data;
      } else {
        codeHandle(response.status, response.data.msg);
      }
    }
  },
  (error: any) => {
    // 服务器异常500等
    loading.error();
    const { response } = error;
    if (response) {
      // if (response.data.code) {
      //   return response.data;
      // } else {
      //   codeHandle(response.status, response.data.message);
      // }
      const urls = ['https://apibuyer.echobro.com/members'];
      if (urls.indexOf(response.config.url) > -1) {
        return response.data;
      } else {
        if (response.data) {
          codeHandle(response.status, response.data.message);
        }
      }
    } else {
      // 网络超时提醒
      if (error.message.includes('timeout')) {
        codeHandle(100, i18n.global.t('error.timeout'));
      }
    }
    return error;
  },
);

export default HTTP;

/*
 * @Description: 国际化中文
 * @Author: Mandy.yu
 * @Date: 2021-12-13 17:54:47
 * @LastEditors: Mandy.yu
 * @LastEditTime: 2023-04-20 20:32:39
 */
export default {
  route: {
    root: '主页',
    game: '游戏',
    hot: '热销产品',
    new: '最近发布',
    discount: '限时特惠',
    coming: '即将推出',
    help: '帮助',
    favorite: '喜欢',
    cart: '购物车',
    account: '我的帐户',
    order: '我的游戏',
    set: '帐号设置',
    bind: '帐号绑定',
    register: '注册',
    login: '登录',
    detail: '详情',
    search: '搜索',
    pay: '支付',
    menu: '菜单',
    logout: '退出登录',
    orderDetail: '订单详情',
    activating: '激活',
    aboutus: '关于我们',
    contact: '联系我们',
    policy: '政策',
    use: '使用条款',
    refund: '退费条款',
    privacy: '隐私政策'
  },
  button: {
    search: '搜索',
    login: '登录',
    register: '注册',
    confirm: '确认',
    pay: '去支付',
    add: '添加到购物车',
    added: '已添加购物车',
    showCode: '显示激活码',
    modify: '修改',
    cancel: '取消',
    bind: '绑定',
    bound: '已绑定'
  },
  login: {
    title: '登录',
    account: '请输入你的邮箱',
    password: '请输入你的密码',
    check: '请输入验证码',
    or: '或',
    disaccount: '还没有帐户？',
  },
  register: {
    title: '注册',
    account: '请输入你的邮箱',
    password: '请输入你的密码',
    confpassword: '请确认你的密码',
    check: '请输入验证码',
    or: '或',
    hasaccount: '已有帐户？',
    email: '请输入邮箱验证码',
    emailCode: '获取邮箱验证码',
    emailReCode: '重新获取',
    Obtaining: '获取中',
  },
  home: {
    hotProducts: '当前热销',
    newProducts: '最新上架',
    disProducts: '限时特惠',
    more: '查看更多',
    partner: '合作伙伴',
  },
  detail: {
    about: '关于这个游戏',
    system: '游戏配置',
    info: '游戏详情',
    language: '支持语言',
    rating: '游戏评级',
    legal: '法律信息',
    action: '激活要求',
    drm: '激活平台',
    platform: '平台',
    active: '激活地区',
    activeType: '激活类型',
    os: '操作系统',
    cpu: '处理器',
    memory: '内存',
    graphics: '显卡',
    storage: '硬盘',
    minimum: '最低配置',
    recommend: '推荐配置',
    developer: '游戏开发者',
    publisher: '出版商',
    releaseTime: '发布日期',
    gameType: '游戏类型',
    activeView: '查看更多可激活地区',
    isDLC: '本产品为DLC，需要本体才可运行',
    body: '本体链接',
    本体: '游戏',
    DLC: 'DLC',
    Activate: '可激活',
    Unavailable: '不可激活'
  },
  search: {
    sort: {
      title: '排序方式',
      default: '默认',
      release: '发布时间',
      alphabetically: '字母顺序',
      price_lh: '价格(由低到高)',
      price_hl: '价格(由高到低)',
    },
    show: '展示',
    more: '查看全部',
    hide: '收起',
    language: {
      title: '语言',
      simpChinese: '简体中文',
      tradChinese: '繁体中文',
      russian: '俄语',
      english: '英文',
      japanese: '日文',
      korean: '韩语',
      dutch: '荷兰语',
      portuguese: '葡萄牙语',
      french: '法语',
      spanish: '西班牙语',
      german: '德语',
      italian: '意大利语',
      swedish: '瑞典语',
      greek: '希腊语',
      svenska: '瑞典语',
    },
    price: {
      title: '价格',
      sale: '正在打折',
      noDiscount: '不打折',
      hPrice: '最高价',
      lPrice: '最低价',
      confirm: '确定',
      reset: '重置',
    },
    OS: {
      title: '操作系统',
    },
    scene: {
      title: '使用场景',
      Steam: 'Steam',
      GOG: 'GOG',
      Epic: 'Epic',
      Uplay: 'Uplay',
      Origin: 'Origin',
    },
    publishers: {
      title: '发行商',
    },
    developers: {
      title: '开发商',
    },
    label: {
      title: '热门标签',
    },
    releaseTime: {
      title: '发售时间',
      presale: '预售',
      nopresale: '非预售',
      starttime: '开始时间',
      endtime: '结束时间',
      confirm: '确定',
      reset: '重置',
    },
    players: {
      title: '玩家人数',
      single: '单人',
      multi: '多人',
      screen_co_op: '同屏/分屏多人',
      lan_co_op: '局域网多人',
      multi_online: '线上多人',
      cross_platform: '跨平台多人',
      other: '其他',
    },
    content: {
      title: '本体、DLC', //不太清楚这个要不要翻译
      basegame: '游戏本体',
      DLC: 'DLC',
      bundle: '捆绑包',
    },
  },
  account: {
    date: '日期',
    game: '游戏',
    num: '数量',
    price: '价格',
    status: '订单状态',
    number: '编号',
    view: '查看订单',
    pay: '去支付',
    tip: '单击此处显示您的激活码。完成此步骤后，您将无法获得退款！请注意',
    username: '用户名',
    待付款: '待付款',
    待发货: '待发货',
    已发货: '已发货',
    已取消: '已取消',
    alertMessage: '此操作将取消订单,是否继续？',
    confirm: '确定',
    undo: '取消',
    cancel: '取消订单',
    region: '激活地区',
    currency: '货币',
    platform: '激活平台',
    编号: '编号:'
  },
  set: {
    title: '更改密码',
    oldPass: '请输入原密码',
    newPass: '请输入新密码',
    confPass: '请确认新密码',
  },
  bind: {
    title: '绑定账号',
    contentText:"需要开启VPN才能绑定",
  },
  footer: {
    info: {
      title: '信息',
      about: '关于我们',
      partners: '官方合作伙伴',
      help: '帮助中心',
      use: '使用条款',
      privacy: '隐私政策',
      refund: '退款政策',
      contact: '联系我们',
    },
    activate: {
      title: '如何激活您的游戏代码',
      steam: 'Steam 代码激活',
      antsteam: 'Antstream 代码激活',
      epic: 'Epic Games 代码激活',
      rockstar: 'Rockstar 代码激活',
      ubisoft: 'Ubisoft Connect 代码激活',
      origin: '原代码激活',
    },
    community: {
      title: '联系我们',
    },
  },
  activating: {
    steam: {
      title: '在 Steam 上激活产品',
      step1: '如何创建 Steam 帐户？',
      content1: '1.前往以下网站： https://store.steampowered.com/login/\n' +
        '2.点击“加入 Steam”\n' +
        '3.根据提示创建您的帐户\n',
      step2: '请按照以下步骤，在 Steam 上激活新的零售产品:',
      content2: '1.启动 Steam 并登录至您的 Steam 帐户\n' +
        '2.点击“游戏菜单”\n' +
        '3.选择“在 Steam 上激活产品…”\n' +
        '4.按照对话框的指引完成激活操作\n'
    },
    antsteam: {
      title: '在 Antstream 上激活产品',
      step1: '我如何创建帐户?',
      content1: '1.前往 https://accounts.antstream.com/create-user\n' +
        '2.根据提示创建您的帐户\n',
      step2: '请按照以下说明激活您的产品:',
      content2: '1.访问 https://accounts.antstream.com/ 并使用您的 Antstream 账户\n' +
        '2.单击“Redeem Code”并按照说明进行操作。\n' +
        '3.下载并安装客户端。\n' +
        '4.启动应用程序并使用您的 Antstream 帐户登录\n'
    }
  },
  cart: {
    title: '结算',
    instock: '有货',
    outstock: '无货',
    drm: '游戏平台',
    activates: '可激活地区',
    qty: '数量',
    delete: '删除',
    payment: '付款方式',
    total: '总计',
    save: '为您节省',
    coupon: '未使用优惠券',
    pay: {
      card: '银行卡支付',
      wechat: '微信支付',
      alipay: '支付宝支付',
    },
  },
  pay: {
    tip: '使用微信支付方式進行支付。您将通过二维码完成支付。',
    success: '支付成功',
  },
  rules: {
    email: {
      empty: '邮箱地址不能为空',
      goodname: '请输入正确的邮箱地址',
    },
    captcha: {
      empty: '验证码不能为空',
    },
    sms_code: {
      empty: '验证码不能为空',
    },
    password: {
      empty: '请输入密码',
    },
    conf_password: {
      empty: '请确认密码',
    },
    old_password: {
      empty: '请输入原密码',
    },
    new_password: {
      empty: '请输入新密码',
    },
    same_password: {
      verity: '两次密码输入不一致',
    },
  },
  message: {
    cart: {
      add_success: '添加购物车成功',
      del_success: '移除购物车成功',
      num_success: '修改数量成功',
    },
    like: {
      add: '添加收藏成功',
      del: '取消收藏成功',
    },
    password: {
      update: '密码修改成功',
    },
  },
  error: {
    timeout: '网络超时',
  },
  about: {
    title: '关于回声',
    info: "我们来自腾讯、网易、完美世界等中国顶级游戏公司。核心成员都有超过10年的游戏行业经验。但这不是重点。我们想做的是让每一款优秀的游戏都有与其高质量相对应的销量和人气。到目前为止，你看到的只是我们正在做的事情的一部分。",
    ceo: "无论知名与否，我们都会尽最大努力为更多玩家提供更多高质量的游戏。我们渴望成为桥梁和纽带。",
    bd: "Cospa Game团队的每一位成员都是而且必须是经验丰富的玩家。我们自己是，也将永远是我们的第一批用户。",
    it: '信息技术与数字娱乐相辅相成，共同发展。游戏行业即将迎来一个新的时代！',
    history: {
      title: "公司历程",
      2020: "2020年及更早",
      2021: "2021年",
      2022: "2022年",
      2023: "2023年",
      time1: "2016年4月，回声游戏团队成立。",
      time2: "Cospa Game自营零售店上线。",
      time3: "Cospa Game自主研发的店铺管理系统上线，包括产品、库存、订单管理系统、营销工具、发行商控制中心等。",
      time4: "Cospa Game已与50多家世界知名游戏开发商/发行商签订合同，包括：Paradox Interactive、Team17 Digital、Gearbox Publishing、Frontier Developments、Techland、Annapurna Interactive、tinyBuild、Unknown Worlds Entertainment、Avalanche Studios Group、Saber Interactive、Raw Fury、Hello Games等。",
      time5: 'Cospa Game成为日本游戏开发商TECOPARK的唯一指定运营商，负责《PICO PARK》游戏在PC上的推广和销售。截至2022年底，《PICO PARK》在PC上累计销量已超过170万份。',
      time6: '回声游戏成为应用程序“RED”的游戏服务提供商。Cospa团队与“小红书”合作举办了许多以游戏为主题的活动和比赛。以多人合作游戏《PICO PARK》为例，累计观看人数超过560万。<em style="color:red">*</em>“小红书”是中国年轻女性最常用的本地生活方式APP，截至2023-06，其MAU已超过2.6亿。',
      time7: 'Cospa Game已与100多家世界知名游戏开发商/发行商签订合同，包括：Take Two Interactive、PlayStation Studios、华纳兄弟游戏、SCS Software、Good Shepherd Entertainment、CI Games、SNK CORPORATION、Kepler Interactive、Motion Twin、Nacon、Bloober Team SA、Quantic Dream、Merge Games等。',
      time8: '借助阿里云平台和人工智能技术，Cospa团队开发了一个适用于PC游戏/主机游戏的AI客服系统。',
      time9: '截至2022年底，回声游戏的媒体矩阵（包括：自营媒体、签约游戏网站、论坛、社区、KOL等）已覆盖1000多万玩家。其中，与Cospa合作的粉丝超过10万的KOL数量超过150个；粉丝超过100万的KOL数量超过30个。',
      time10: 'Cospa Game已与200多家世界知名游戏开发商/发行商签订合同，其中包括：11 Bit Studios、505 Games、Jagex Ltd、Landfall Games、Marvelous Europe、Hooded Horse、DONTNOD Entertainment、M2H等。',
      time11: 'Cospa Game成为哔哩哔哩在PC游戏领域的服务提供商。Cospa Game和哔哩哔哩网联合组织了一批优秀的上传者，开展游戏直播、视频创作和线下展示活动，旨在向玩家推荐更多优质的PC游戏<p class="intr" style=“color:rgba（51,51,51，.5）；”>*哔哩哔哩网是中国最大的ACG视频网站。截至2023-03年，其MAU已超过3.15亿</p>',
      time12: '回声游戏成为抖音网（抖音）的指定游戏服务提供商，抖音网是中国排名第一的移动视频社交应用。同年，回声游戏也成为快手网的指定游戏服务提供商。'
    },
    partner: {
      text1: '我们已经与',
      text2: '世界知名游戏发行商合作'
    },
    number: {
      title: '公司成就',
      sale: '在售产品',
      sold: '游戏年销量',
      kol: '合作知名达人',
      signed: '签约著名出版商',
      user: '累计付费用户',
      game: '合作的著名游戏'
    },
    contant: '我们真诚地希望与世界各地的游戏发行商、游戏行业从业者和游戏爱好者交流与合作。'
  },

};


import {defineComponent, ref, nextTick, provide, reactive, toRefs, watchEffect} from 'vue';
import Header from './components/Header.vue';
import Nav from './components/Nav.vue';
import Breadcrumb from './components/Breadcrumb.vue';
import Footer from './components/Footer.vue';
import {useRoute} from 'vue-router';
import {useStore} from '@/store';
import api from '@/http/api';

export default defineComponent({
  components: {
    Header,
    Nav,
    Breadcrumb,
    Footer,
  },
  setup() {
    const isRouterAlive = ref(true);
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      isHome: route.name === 'home',
      allLikes: [] as Array<string>,
      allCart: [] as Array<string>,
      showMenu: false,
    });
    const onReload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };
    const Home = {
      // 喜欢游戏ID集合
      getLikes: async () => {
        state.allLikes = await api.games.allLikes();
        // console.log('====', state.allLikes);
        store.commit('setLikes', state.allLikes);
      },
      // 购物车列表
      getCart: async () => {
        const params = {
          way: 'CART',
        };
        const data = await api.cart.getAll(params);
        if (data.cart_list && data.cart_list.length > 0) {
          data.cart_list[0].sku_list.forEach((item) => {
            state.allCart.push(item.sku_id);
          });
          store.commit('setCart', state.allCart);
        }
        store.commit('setCart', state.allCart);
      },
      showMenu: (value) => {
        state.showMenu = value;
      },
      getUserInfo: async () => {
        const data = await api.global.getUserInfo();
        if (data.message) {
          // sessionStorage.removeItem('userToken');
        } else {
          Home.getLikes();
          Home.getCart();
        }
      },
    };
    provide('reload', onReload);
    watchEffect(() => {
      state.isHome = route.name === 'home';
    });
    /**　初始化 */
    const init = async () => {
      if (sessionStorage.getItem('userToken')) {
        Home.getUserInfo();
      }
    };
    init();
    return {
      isRouterAlive,
      ...toRefs(state),
      route,
      Home,
    };
  },
});

/*
 * @Description: 国际化英文
 * @Author: Mandy.yu
 * @Date: 2021-12-13 17:55:23
 * @LastEditors: Mandy.yu
 * @LastEditTime: 2023-04-20 20:33:51
 */
export default {
  route: {
    root: 'Home',
    game: 'Games',
    hot: 'Top sellers',
    new: 'New releases',
    discount: 'On Sale',
    coming: 'Coming soon',
    help: 'Help',
    favorite: 'Favorites',
    cart: 'Cart',
    account: 'My profile',
    order: 'My games',
    set: 'Set',
    bind: 'Bind',
    register: 'Register',
    login: 'Login',
    detail: 'Detail',
    search: 'Search',
    pay: 'Pay',
    menu: 'Menu',
    logout: 'Logout',
    orderDetail: 'Order detail',
    activating: 'activating',
    aboutus: 'About',
    contact: 'Contact Us',
    policy: 'Policy',
    use: 'Terms of Use',
    refund: 'Refund Policy',
    privacy: 'Privacy Policy'
  },
  button: {
    search: 'Search',
    login: 'Log in',
    register: 'Create account',
    confirm: 'Confirm',
    pay: 'Checkout',
    add: 'Add to cart',
    added: 'In cart',
    showCode: 'Show activation code',
    modify: 'Modify',
    cancel: 'Cancel',
    bind: 'Bind',
    bound: 'Bound'
  },
  login: {
    title: 'Sign In',
    account: 'Enter your email',
    password: 'Enter your password',
    check: 'Enter your check code',
    or: 'OR',
    disaccount: 'No account yet?',
  },
  register: {
    title: 'Register',
    account: 'Enter your account',
    password: 'Enter your password',
    confpassword: 'Confirm your password',
    check: 'Enter your check code',
    or: 'OR',
    hasaccount: 'Already account yet?',
    email: 'Enter your email code',
    emailCode: 'Get email code',
    emailReCode: 'Get code again',
    Obtaining: 'Obtaining',
  },
  home: {
    hotProducts: 'Top sellers',
    newProducts: 'Newest releases',
    disProducts: 'Limited time discount',
    more: 'More',
    partner: 'Partner',
  },
  detail: {
    about: 'About this game',
    system: 'System requirements',
    info: 'Game details',
    language: 'Supported languages',
    rating: 'Content rating',
    legal: 'Legal notices',
    action: 'ACTIVATION',
    drm: 'DRM',
    platform: 'Platform',
    active: 'Activates',
    activeType: 'Active type',
    os: 'OS',
    cpu: 'Processor',
    memory: 'Memory',
    graphics: 'Graphics',
    storage: 'Storage',
    minimum: 'Minimum',
    recommend: 'Recommended',
    developer: 'Developers',
    publisher: 'Publisher',
    releaseTime: 'Release time',
    gameType: 'Game type',
    activeView: 'Show regions',
    isDLC: 'This product is a DLC and requires a body to run',
    body: 'Body Link',
    本体: 'Game',
    DLC: 'DLC',
    Activate: 'Activates',
    Unavailable: 'Unavailable'
  },
  search: {
    sort: {
      title: 'Sort by',
      default: 'Default',
      release: 'Release date',
      alphabetically: 'Alphabetically',
      price_lh: 'Price(low-high)',
      price_hl: 'Price(high-low)',
    },
    show: 'Show',
    more: 'More',
    hide: 'Hide',
    language: {
      title: 'LANGUAGE',
      simpChinese: 'Simplified Chinese',
      tradChinese: 'Traditional Chinese',
      russian: 'Russian',
      english: 'English',
      japanese: 'Japanese',
      korean: 'Korean',
      dutch: 'Dutch',
      portuguese: 'Portuguese',
      french: 'French',
      spanish: 'Spanish',
      german: 'German',
      italian: 'Italian',
      swedish: 'Swedish',
      greek: 'Greek',
      svenska: 'Svenska',
    },
    price: {
      title: 'Price Range',
      sale: 'On Sale',
      noDiscount: 'No discount',
      hPrice: 'Highest',
      lPrice: 'Lower',
      confirm: 'Confirm',
      reset: 'Reset',
    },
    OS: {
      title: 'Operating system',
    },
    scene: {
      title: 'Scene',
      Steam: 'Steam',
      GOG: 'GOG',
      Epic: 'Epic',
      Uplay: 'Uplay',
      Origin: 'Origin',
    },
    publishers: {
      title: 'Publisher',
    },
    developers: {
      title: 'Developers',
    },
    label: {
      title: 'Popular Label',
    },
    releaseTime: {
      title: 'Sale time',
      presale: 'Presale',
      nopresale: 'Non presale',
      starttime: 'Start time',
      endtime: 'End time',
      confirm: 'Confirm',
      reset: 'Reset',
    },
    players: {
      title: 'Players',
      single: 'Single-Player',
      multi: 'Multi-Player',
      screen_co_op: 'Shared/Split Screen Co-op',
      lan_co_op: 'LAN Co-op',
      multi_online: 'Multi-Player Online',
      cross_platform: 'Cross-Platform Multiplayer',
      other: 'Other',
    },
    content: {
      title: 'Base Game、DLC', //不太清楚这个要不要翻译
      basegame: 'Base Game',
      DLC: 'DLC',
      bundle: 'Bundle',
    },
  },
  account: {
    date: 'Date',
    game: 'Game',
    num: 'Num',
    price: 'Price',
    status: 'Status',
    number: 'Number',
    view: 'View',
    pay: 'Pay',
    tip: 'Click here to display your activation code. After completing this step, you will not be able to receive a refund! Please note that',
    username: 'User name',
    待付款: 'Pending Payment',
    待发货: 'To be delivered',
    已发货: 'Delivered',
    已取消: 'Cancelled',
    alertMessage: 'This action will cancel the order. Do you want to continue?',
    confirm: 'Confirm',
    undo: 'Cancel',
    cancel: 'Cancel Order',
    region: 'Region',
    currency: 'Currency',
    platform: 'Platform',
    编号: 'Order Number:'
  },
  set: {
    title: 'Update password',
    oldPass: 'Enter your old password',
    newPass: 'Enter your new password',
    confPass: 'Confirm your new password',
  },
  bind: {
    title: 'BindAccount',
    contentText:"Need VPN",
  },
  footer: {
    info: {
      title: 'Information',
      about: 'About us',
      partners: 'Official partners',
      help: 'Help Center',
      use: 'Terms of use',
      privacy: 'Privacy Policy',
      refund: 'Refund Policy',
      contact: 'Contact us',
    },
    activate: {
      title: 'How to activate your game code',
      steam: 'Steam code activation',
      antsteam: 'Antstream code activation',
      epic: 'Epic Games code activation',
      rockstar: 'Rockstar code activation',
      ubisoft: 'Ubisoft Connect code activation',
      origin: 'Origin code activation',

    },
    community: {
      title: 'Community',
    },
  },
  activating: {
    steam: {
      title: 'Activating a Product on Steam',
      step1: 'How do I create a Steam account?',
      content1: '1.Go to the following website: https://store.steampowered.com/login/\n' +
        '2.Click on "Join Steam"\n' +
        '3.Follow the prompts to create your account.\n',
      step2: 'Please follow these instructions to activate your product on Steam:',
      content2: '1.Launch the Steam client software and log into your Steam account.\n' +
        '2.Click the Games menu option at the top of the Steam client.\n' +
        '3.3.Choose Activate a Product on Steam...\n' +
        '4.Follow the onscreen instructions to complete the process'
    },
    antsteam: {
      title: 'Activating a Product on Antstream',
      step1: 'How do I create an account?',
      content1: '1.Go to https://accounts.antstream.com/create-user\n' +
        '2.Follow the prompts to create your account',
      step2: 'Please follow these instructions to activate your product:',
      content2: '1.Go to https://accounts.antstream.com/ and with your Antstream Account\n' +
        '2.Click on "Redeem Code" and follow the instructions.\n' +
        '3.Download and install the client.\n' +
        '4.Start the application and log in with your Antstream Account.'
    }
  },
  cart: {
    title: 'Cart',
    instock: 'In stock',
    outstock: 'Out stock',
    drm: 'DRM',
    activates: 'Activates',
    qty: 'Qty',
    delete: 'Delete',
    payment: 'Payment method',
    total: 'Total',
    save: 'You‘re saving ',
    coupon: 'no coupon applied',
    pay: {
      card: 'Card Payment',
      wechat: 'WeChat',
      alipay: 'Alipay',
    },
  },
  pay: {
    tip: 'WeChat Pay',
    success: 'Success',
  },
  rules: {
    email: {
      empty: 'Email is required',
      goodname: 'Please input correct email address',
    },
    captcha: {
      empty: 'Captcha is required',
    },
    sms_code: {
      empty: 'Verification Code is required',
    },
    password: {
      empty: 'Please input the password',
    },
    conf_password: {
      empty: 'Please confirm the password',
    },
    old_password: {
      empty: 'Please input the old password',
    },
    new_password: {
      empty: 'Please input the new password',
    },
    same_password: {
      verity: ' The password and confirmation password are different',
    },
  },
  message: {
    cart: {
      add_success: 'Successfully added to cart',
      del_success: 'Successfully removed to cart',
      num_success: 'Successfully modified amount',
    },
    like: {
      add: 'Successfully added favorite ',
      del: 'Successfully removed favorite',
    },
    password: {
      update: 'Successfully modified password',
    },
  },
  error: {
    timeout: 'Error Timeout',
  },
  about: {
    title: 'ABOUT Cospa',
    info: "We come from top Chinese game companies such as Tencent, Netease, and Perfect World. The core members all have more than 10 years of experience in the game industry. But that's not the point. What we want to do is to make every excellent game have sales and popularity corresponding to its high quality. What you see so far is only part of what we are doing.",
    ceo: "We do our best to deliver more high-quality games to more players, whether famous or not. We aspire to be bridges and links.",
    bd: "Every member of the Cospa Game team is and must be a seasoned gamer. We ourselves are, and will always be, our first users.",
    it: 'Information technology and digital entertainment complement each other and develop together. The game industry is about to usher in a new era!',
    history: {
      title: "Company History",
      2020: "Year 2020 and Earlier",
      2021: "Year 2021",
      2022: "Year 2022",
      2023: "Year 2023",
      time1: "In April 2016, the Cospa Game team was established.",
      time2: "The self-operated retail store of Cospa Game was launched.",
      time3: "The store management system independently developed by Cospa Game was launched, including product, inventory, order management system, marketing tools, publisher control center, etc. ",
      time4: "Cospa Game has signed contracts with more than 50 world-renowned game developers/publishers, including: Paradox Interactive, Team17 Digital, Gearbox Publishing, Frontier Developments, Techland, Annapurna Interactive, tinyBuild, Unknown Worlds Entertainment, Avalanche Studios Group, Saber Interactive, Raw Fury, Hello Games and more.",
      time5: 'Cospa Game became the only designated operator of Japanese game developer TECOPARK, and was responsible for the promotion and sales of the game "PICO PARK" on PC. By the end of 2022, the cumulative sales volume of PICO PARK on PC has exceeded 1.7 million copies.',
      time6: 'Cospa Game became the game service provider of the APP "RED". And Cospa team has cooperated with "RED" to hold many game-themed activities and competitions. Taking the multiplayer cooperative game PICO PARK as an example, the cumulative number of viewers exceeds 5.6 million.<em style="color: red">*</em> "RED" is the most commonly used local lifestyle APP by young Chinese women, and its MAU have exceeded 260 million by 2023-06',
      time7: 'Cospa Game has signed contracts with more than 100 world-renowned game developers/publishers, including: Take-Two Interactive, PlayStation Studios, Warner Bros. Games, SCS Software, Good Shepherd Entertainment, CI Games, SNK CORPORATION, Kepler Interactive, Motion Twin, Nacon, Bloober Team SA, Quantic Dream, Merge Games and more',
      time8: 'With the help of Alibaba Cloud platform and artificial intelligence technology, the Cospa team has developed an AI customer service system suitable for PC games/console games.',
      time9: 'By the end of 2022, the media matrix of Cospa Game (including: self-operated media, contracted game websites, forums, communities, KOLs, etc.) has covered more than 10 million players. Among them, the number of KOLs with more than 100,000 fans in cooperation with Cospa exceeds 150; and the number of KOLs with more than 1 million fans exceeds 30.',
      time10: 'Cospa Game has signed contracts with more than 200 world-renowned game developers/publishers, including: 11 Bit Studios, 505 Games, Jagex Ltd, Landfall Games, Marvelous Europe, Hooded Horse, DONTNOD Entertainment, M2H and more',
      time11: 'Cospa Game became the service provider of Bilibili.com in the field of PC games. Cospa Game and Bilibili.com jointly organized a group of excellent uploaders to carry out game live broadcast, video creation and offline exhibition activities, aiming to recommend more high-quality PC games to players.<p class="intr" style=" color: rgba(51,51,51,.5);">* Bilibili.com is the largest ACG video website in China. As of 2023-03, its MAU have exceeded 315 million</p>',
      time12: 'Cospa Game became the designated game service provider for Douyin.com (Tiktok), the No.1 mobile video social APP in China. In the same year, Cospa Game also became the designated game service provider of Kuaishou.com.'
    },
    partner: {
      text1: 'We have cooperated with more than',
      text2: 'world-renowned game publishers'
    },
    number: {
      title: 'THE NUMBERS',
      sale: 'Products on sale',
      sold: 'Games sold per year',
      kol: 'Famous influencers',
      signed: 'Well-known publishers',
      user: 'Cumulative paid customers',
      game: 'Famous game sites'
    },
    contant: 'We sincerely hope to communicate and cooperate with game publishers, game industry practitioners and game lovers around the world'
  }
};

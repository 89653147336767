/*
 * @Description: 图标
 * @Author: Mandy.yu
 * @Date: 2022-04-18 13:38:13
 * @LastEditors: your name
 * @LastEditTime: 2022-04-18 16:45:10
 */
import * as Icons from '@element-plus/icons-vue'
export default function loadComponent(app: any) {
  for (const key in Icons) {
    app.component(key, Icons[key as keyof typeof Icons])
  }
}
/*
 * @Description: 
 * @Author: Mandy.yu
 * @Date: 2022-04-18 13:37:57
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-03-16 12:15:16
 */

import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css';
export default function loadComponent(app: any) {
  app.use(ElementPlus, {size: 'default'})
}

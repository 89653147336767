/*
 * @Description:
 * @Author: Mandy.yu
 * @Date: 2023-03-15 13:45:35
 * @LastEditors: Mandy.yu
 * @LastEditTime: 2023-03-30 13:49:14
 */
import { createStore } from 'vuex';
import { store as global, GlobalStore, GlobalState } from '@/store/global';

export interface RootState {
  global: GlobalState;
}

export type Store = GlobalStore<Pick<RootState, 'global'>>;

export const store: Store = createStore({
  modules: {
    global,
  },
});

async function getPublicIP(url, cb) {
  const httpRequest = new XMLHttpRequest();
  httpRequest.open('GET', url, true);
  httpRequest.send();
  httpRequest.onreadystatechange =  await  function () {
    if (httpRequest.readyState == 4 && httpRequest.status == 200) {
      const json = httpRequest.responseText;
      cb(json);
    }
  };
}
getPublicIP('https://api.ipify.org/?format=json', function (data) {
  // setSession("Real-IP", "121.56.29.249");
  data = JSON.parse(data)
  // console.log(data)
  if (data.ip){
    // console.log(111111)
    sessionStorage.setItem('Real-IP', data.ip);
  }
})

export function useStore(): Store {
  return store as Store;
}

<!--
* @Description: 
* @Author: Mandy.yu
* @Date: 2023-03-15 13:45:35
* @LastEditors: Mandy.yu
* @LastEditTime: 2023-04-13 10:58:25
-->
<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: PuHuiTi, HanYi65, Spotmonkey, HanYiLingXinTiJian, Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

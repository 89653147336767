/*
 * @Description: 路由
 * @Author: Mandy.yu
 * @Date: 2023-03-15 13:45:35
 * @LastEditors: Mandy.yu
 * @LastEditTime: 2023-04-20 20:20:32
 */

import {createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw} from 'vue-router';
import {useStore} from '@/store';
import {v4 as uuidv4} from 'uuid';
import NProgress from 'nprogress';
import Layout from '@/layout/Index.vue';

NProgress.configure({showSpinner: false});

const store = useStore();

let routes: Array<RouteRecordRaw>;
routes = [
  {
    path: '/',
    name: 'root',
    component: Layout,
    children: [
      {
        path: 'activating/:platform',
        name: 'activating',
        component: () => import(/* webpackChunkName: "Detail" */ '@/views/ActivatingMessage.vue'),
      },
      {
        path: 'aboutus',
        name: 'aboutus',
        component: () => import(/* webpackChunkName: "Detail" */ '@/views/Aboutus.vue'),
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "Detail" */ '@/views/Contact.vue'),
      },
      {
        path: '/',
        name: 'home',
        alias: '/home',
        component: () => import(/* webpackChunkName: "HomeView" */ '@/views/Home.vue'),
      },
      {
        path: 'detail/:id',
        name: 'detail',
        component: () => import(/* webpackChunkName: "Detail" */ '@/views/Detail.vue'),
      },
      {
        path: 'hot',
        name: 'hot',
        component: () => import(/* webpackChunkName: "Search" */ '@/views/Search.vue'),
      },
      {
        path: 'new',
        name: 'new',
        component: () => import(/* webpackChunkName: "Search" */ '@/views/Search.vue'),
      },
      {
        path: 'discount',
        name: 'discount',
        component: () => import(/* webpackChunkName: "Search" */ '@/views/Search.vue'),
      },
      {
        path: 'favorite',
        name: 'favorite',
        component: () => import(/* webpackChunkName: "Search" */ '@/views/Search.vue'),
      },
      {
        path: 'search',
        name: 'search',
        component: () => import(/* webpackChunkName: "Search" */ '@/views/Search.vue'),
      },
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "Register" */ '@/views/Register.vue'),
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import(/* webpackChunkName: "Cart" */ '@/views/Cart.vue'),
      },
      {
        path: 'pay',
        name: 'pay',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Pay.vue'),
      },
      {
        path: 'account',
        name: 'account',
        redirect: '/account/order',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/account/Index.vue'),
        children: [
          {
            path: 'order',
            name: 'order',
            component: () =>
              import(/* webpackChunkName: "AccountOrders" */ '@/views/account/Account_Orders.vue'),
            children: [],
          },
          {
            path: 'detail',
            name: 'orderDetail',
            props: true,
            component: () =>
              import(/* webpackChunkName: "OrderDetail" */ '@/views/account/Order_Detail.vue'),
          },
          {
            path: 'set',
            name: 'set',
            component: () =>
              import(/* webpackChunkName: "AccountSet" */ '@/views/account/Account_Set.vue'),
          },
          {
            path: 'bind',
            name: 'bind',
            component: () =>
              import(/* webpackChunkName: "AccountSet" */ '@/views/account/Account_Bind.vue'),
          },
        ],
      },
      {
        path: '/policy',
        name: 'policy',
        redirect: '/policy/use',
        component: () => import(/* webpackChunkName: "policy" */ '@/views/Policy.vue'),
        children: [
          {
            path: 'use',
            name: 'use',
            component: () =>
              import(/* webpackChunkName: "OrderDetail" */ '@/views/Policy.vue'),
          },
          {
            path: 'privacy',
            name: 'privacy',
            component: () =>
              import(/* webpackChunkName: "OrderDetail" */ '@/views/Policy.vue'),
          },
          {
            path: 'refund',
            name: 'refund',
            component: () =>
              import(/* webpackChunkName: "OrderDetail" */ '@/views/Policy.vue'),
          },
          ]
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from) => {
  NProgress.start();
  if (!store.state.global.uuid) {
    const uuid = uuidv4();
    store.commit('setUUID', uuid);
    localStorage.setItem('uuid', uuid);
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;

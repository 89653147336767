/*
 * @Description:
 * @Author: Mandy.yu
 * @Date: 2021-09-11 10:35:51
 * @LastEditors: Mandy.yu
 * @LastEditTime: 2023-04-12 19:13:25
 */
import { createI18n } from 'vue-i18n';
import elementEnLocale from 'element-plus/es/locale/lang/en';
import elementZhLocale from 'element-plus/es/locale/lang/zh-cn';
import enLocale from './en';
import zhLocale from './zh';

const messages = {
  'en-US': {
    ...enLocale,
    ...elementEnLocale,
  },
  'zh-CN': {
    ...zhLocale,
    ...elementZhLocale,
  },
} as any;

export const getLocale = () => {
  if (localStorage.getItem('language') !== null) {
    const language = localStorage.getItem('language');
    if (language && messages[language]) {
      return language;
    }
  }
  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'en-US';
};
const i18n: { [key: string]: any } = createI18n({
  legacy: false,
  locale: getLocale(),
  messages: messages,
  silentTranslationWarn: true,
});

export default i18n;

/*
 * @Description:
 * @Author: Mandy.yu
 * @Date: 2022-04-19 17:48:38
 * @LastEditors: Mandy.yu
 * @LastEditTime: 2023-04-15 13:17:09
 */
import qs from 'qs';
import axios from './index';
import md5 from 'js-md5';
import { useStore } from '@/store';
import { Header } from 'element-plus/es/components/table-v2/src/components';

const store = useStore();

const uuid = store.state.global.uuid;

interface paramsModel {
  [key: string]: any;
}

const baseUrl = {
  // base: 'http://localhost:8301',
  // buyer: 'http://localhost:8304',
  // base: 'http://152.136.201.54:8301',
  // buyer: 'http://152.136.201.54:8304',
  base: 'https://apibase.echobro.com',
  buyer: 'https://apibuyer.echobro.com',
  seller: 'https://apiseller.echobro.com',
  admin: 'https://apiadmin.echobro.com',
};

const global = {
  // 获取图片验证码
  getImgCode(params: paramsModel, type: paramsModel): { [key: string]: any } {
    // console.log('imgCode', uuid, localStorage.getItem('uuid'), '===', store.state.global.uuid);
    return axios.get(`${baseUrl.base}/captchas/${store.state.global.uuid}/${params.type}`, {
      responseType: 'blob',
    });
  },
  // 发送邮箱验证码
  postEmailCode(params: paramsModel, email: string): { [key: string]: any } {
    // console.log('params', params, qs.stringify(params));
    return axios.post(`${baseUrl.buyer}/passport/register/emailcode/${email}`, {
      uuid: store.state.global.uuid,
      ...params,
    });
  },
  // 注册
  postRegister(params: paramsModel): { [key: string]: any } {
    params.password = md5(params.password);
    return axios.post(`${baseUrl.buyer}/passport/register/pcemail`, params);
  },
  // 登录
  postLogin(params: paramsModel): { [key: string]: any } {
    params.password = md5(params.password);
    return axios.post(`${baseUrl.buyer}/passport/login`, {
      uuid: store.state.global.uuid,
      ...params,
    });
  },
  // 退出
  logout(): { [key: string]: any } {
    return axios.post(`${baseUrl.buyer}/members/logout`);
  },
  // 修改密码
  updatePWS(params: paramsModel): { [key: string]: any } {
    params.password_new = md5(params.password_new);
    params.password_old = md5(params.password_old);
    params.password = params.password_new;
    return axios.post(`${baseUrl.buyer}/passport/find-pwd/update_psd`, params);
  },
  // 修改密码
  getUserInfo(): { [key: string]: any } {
    return axios.get(`${baseUrl.buyer}/members`);
  },
  // 获取z平台绑定接口
  getBindInfo(params:paramsModel): { [key: string]: any } {
    return axios.get(`${baseUrl.buyer}/members/getBindInfo`, {params});
  },
  // 用户咨询邮件
  sendConsultEmail(params: paramsModel): { [key: string]: any } {
    return axios.post(`${baseUrl.buyer}/pages/search-param`, params);
  },
};
const games = {
  // 查询banner
  getBanner(): { [key: string]: any }[] {
    return axios.get(`${baseUrl.buyer}/shops/sildes/20`, {
      responseType: 'blob',
    });
  },
  // 游戏查询
  getGames(params: paramsModel): { [key: string]: any } {
    return axios.get(`${baseUrl.buyer}/goods/search`, { params });
  },

  // 游戏详情
  getGameDetail(params: paramsModel): { [key: string]: any } {
    return axios.get(`${baseUrl.buyer}/goods/${params.id}`);
  },
  // 游戏查询
  getGamesSku(params: paramsModel): { [key: string]: any }[] {
    return axios.get(`${baseUrl.buyer}/goods/${params.id}/skus`);
  },
  // 喜欢游戏列表
  getFavoriteProducts(params: paramsModel): { [key: string]: any } {
    return axios.get(`${baseUrl.buyer}/goods/likesList`);
  },
  // 喜欢/取消喜欢游戏
  setLikeOrCancel(params: paramsModel): { [key: string]: any } {
    return axios.get(`${baseUrl.buyer}/goods/${params.id}/like`);
  },
  // 所有喜欢游戏ID
  allLikes(): Array<string> {
    return axios.get(`${baseUrl.buyer}/goods/likes`);
  },
  // 查询展示params
  getSearchParam (params: paramsModel): { [key: string]: any } {
    return axios.get(`${baseUrl.buyer}/pages/search-param`, { params });
  },
};

const cart = {
  // 购物车列表
  getAll(params: paramsModel): { [key: string]: any } {
    return axios.get(`${baseUrl.buyer}/trade/carts/all`, { params });
  },
  // 添加购物车
  addCart(params: paramsModel): { [key: string]: any } {
    return axios.post(`${baseUrl.buyer}/trade/carts`, params);
  },
  // 添加购物车
  delCart(params: paramsModel): { [key: string]: any } {
    return axios.delete(`${baseUrl.buyer}/trade/carts/${params.id}/sku`);
  },
  // 修改购物车数量
  modifyCart(params: paramsModel): { [key: string]: any } {
    return axios.post(`${baseUrl.buyer}/trade/carts/sku/${params.sku_id}`, params);
  },
  // 订单查询
  getOrders(params: paramsModel): { [key: string]: any } {
    return axios.get(`${baseUrl.buyer}/trade/orders`, { params });
  },
  // 取消订单
  cancelOrders(params: paramsModel): { [key: string]: any } {
    return axios.post(`${baseUrl.buyer}/trade/orders/${params.order_sn}/cancel`, params);
  },
  // 下单
  createOrder(params: paramsModel): { [key: string]: any } {
    return axios.post(`${baseUrl.buyer}/trade/create`, params);
  },
  // 收银台
  getCashier(params: paramsModel): { [key: string]: any } {
    return axios.get(`${baseUrl.buyer}/trade/orders/cashier`, { params });
  },
  // 发起支付
  postPay(params: paramsModel): { [key: string]: any } {
    return axios.get(`${baseUrl.buyer}/order/pay/trade/${params.sn}`, { params });
  },
  // 支付状态查询
  statusPay(params: paramsModel): string {
    return axios.get(`${baseUrl.buyer}/order/pay/weixin/status/${params.sn}`);
  },
  // 密钥查询
  getSn(sn: string, params: paramsModel): Array<{ [key: string]: any }> {
    return axios.get(`${baseUrl.buyer}/trade/orders/secret/${sn}`, { params });
  },
};

export default { global, games, cart };

/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-15 13:45:35
 * @LastEditors: Mandy.yu
 * @LastEditTime: 2023-03-30 14:19:08
 * @FilePath: /cbi-flowable/Users/mandyyu/Documents/Work/CospaGame/cospagames_web/src/main.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { useStore } from './store';
import 'lib-flexible/flexible.js';

import { loadAllPlugins } from '@/plugins';
import '@/assets/css/reset.css';
import '@/assets/css/font.scss';
import '@/assets/css/common.scss';

const store = useStore();
const app = createApp(App);

// 加载所有插件
loadAllPlugins(app);

app.use(store).use(router).mount('#app');


import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
import api from '@/http/api';
export default defineComponent({
  props: {
    cart: {
      type: Array,
      default: () => [],
    },
    likes: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      keyword: '',
      cart: props.cart,
      likes: props.likes,
      login: (sessionStorage.getItem('userToken') as String) || (false as boolean),
      userInfo: JSON.parse(sessionStorage.getItem('userInfo') || '{}'),
      keywordList: [] as Array<{ [key: string]: any }>,
      showKeyword: false,
    });
    watch(
      () => store.state.global.cart,
      (value) => {
        state.cart = value;
      },
    );
    watch(
      () => store.state.global.likes,
      (value) => {
        state.likes = value;
      },
    );
    watch(
      () => store.state.global.userToken,
      (value) => {
        state.login = value;
      },
    );
    watch(
      () => store.state.global.userInfo,
      (value) => {
        state.userInfo = value;
      },
    );
    const Header = {
      toPage: (name: string) => {
        if (!state.login) {
          router.push({ name: 'login' });
        } else {
          router.push({ name });
        }
      },
      toDetail: (product: { [key: string]: any }) => {
        // console.log(product);
        const link = router.resolve({ name: 'detail', params: { id: product.goods_id } });
        window.open(link.href, '_self');
        Header.hideKeyword();
      },
      toSearch: () => {
        router.push({ name: 'search', query: { keyword: state.keyword } });
        Header.hideKeyword();
      },
      showMenu: () => {
        emit('showMenu', true);
      },
      logout: async () => {
        const data = await api.global.logout();
        if (!data) {
          sessionStorage.removeItem('userToken');
          sessionStorage.removeItem('userInfo');
          store.commit('setToken', '');
          store.commit('setUserInfo', {});
          state.login = 'false';
          state.cart = [];
          router.push({ name: 'login' });
        }
      },
      getKeyword: async () => {
        state.showKeyword = true;
        const params = {
          keyword: state.keyword,
          sku_prop: 'channel_Z'
        };
        const res = await api.games.getGames(params);
        state.keywordList = res.data?.slice(0, 5);
      },
      hideKeyword: () => {
        setTimeout(() => {
          state.showKeyword = false;
        }, 500);
      },
    };

    return {
      t,
      ...toRefs(state),
      Header,
    };
  },
});

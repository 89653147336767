/*
 * @Description: 国际化
 * @Author: Mandy.yu
 * @Date: 2022-04-18 16:44:44
 * @LastEditors: your name
 * @LastEditTime: 2022-04-18 16:44:45
 */
import i18n from '@/locales'
export default function loadComponent(app: any) {
  app.use(i18n)
}
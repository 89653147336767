
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { RouteLocationMatched, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ArrowRight } from '@element-plus/icons-vue';
export default defineComponent({
  setup() {
    const route = useRoute();
    const { t } = useI18n();

    const state = reactive({
      breadcrumbs: computed(() => route.matched),
    });
    return {
      t,
      ...toRefs(state),
      ArrowRight,
    };
  },
});


import { defineComponent, inject, computed, reactive, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { emit } from 'process';

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const route = useRoute();
    const languages = {
      'zh-CN': '中文',
      'en-US': 'English',
    } as { [key: string]: string };
    const onReload = inject<Function>('reload');
    const state = reactive({
      navShow: false as Boolean,
      activeName: route.name,
    });
    watch(
      () => props.show,
      (value) => {
        state.navShow = value;
      },
    );
    watch(
      () => route.name,
      (val) => {
        state.activeName = val;
      },
    );

    const language = computed(() => {
      return languages[locale.value];
    });

    const languageCommand = async (lang: string) => {
      if (locale.value === lang) return;
      // const type = route.meta.flag === 'web' ? undefined : 'omd';
      // await store.dispatch(WebActionTypes.SET_LANGUAGE, lang);
      localStorage.setItem('language', lang);
      locale.value = lang;
      document.title = 'Cospa Game-' + t('route.' + String(route.name));

      onReload && onReload();
    };
    const Nav = {
      show: (value: Boolean) => {
        emit('showMenu', value);
        // state.navShow = value;
      },
    };
    return {
      t,
      ...toRefs(state),
      languages,
      language,
      languageCommand,
      Nav,
    };
  },
});

/*
 * @Description: 全局变量
 * @Author: Mandy.yu
 * @Date: 2023-03-30 13:43:40
 * @LastEditors: Mandy.yu
 * @LastEditTime: 2023-04-18 14:06:53
 */

import {
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  Module,
  ActionContext,
  MutationTree,
  ActionTree,
} from 'vuex';

// import { GlobalActionTypes } from './global-types';
import { RootState } from '@/store';

import api from '@/http/api';
import { ElMessage, ElMessageBox } from 'element-plus';

export interface GlobalState {
  uuid: String;
  likes: Array<string | number>;
  cart: Array<string | number>;
  userToken: String;
  userInfo: { [key: string]: any }; // 用户信息
}

interface Mutations<S = GlobalState> {
  setUUID(state: S, uuid: string): void;
  setLikes(state: S, likes: Array<string | number>): void;
  setCart(state: S, cart: Array<string | number>): void;
  setToken(state: S, token: string): void;
  setUserInfo(state: S, info: { [key: string]: any }): void;
}

interface Actions {
  [key: string]: any;
}

export const state: GlobalState = {
  uuid: '',
  likes: [],
  cart: [],
  userToken: '',
  userInfo: {},
};

const mutations: MutationTree<GlobalState> & Mutations = {
  setUUID: (state, uuid) => {
    state.uuid = uuid;
  },
  setLikes: (state, likes) => {
    state.likes = likes;
  },
  setCart: (state, cart) => {
    state.cart = cart;
  },
  setToken: (state, token) => {
    state.userToken = token;
  },
  setUserInfo: (state, info) => {
    state.userInfo = info;
  },
};

const actions: ActionTree<GlobalState, RootState> & Actions = {
  //   [GlobalActionTypes.SET_TOKEN]: async ({ commit }, code) => {
  // const res: { [key: string]: any } = await api.auth.getToken({
  //   code,
  //   redirectUri: `${process.env.VUE_APP_CLIENT_URI}`,
  // });
  // if (res.access_token) {
  //   localStorage.setItem('userToken', res.access_token);
  //   commit('setToken', res.access_token);
  // }
  //   },
};

export type GlobalStore<S = GlobalState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions,
  ): ReturnType<Actions[K]>;
};

export const store: Module<GlobalState, RootState> = {
  state,
  mutations,
  actions,
};

/*
 * @Description:
 * @Author: Mandy.yu
 * @Date: 2022-04-18 13:37:09
 * @LastEditors: your name
 * @LastEditTime: 2022-04-18 13:37:10
 */

import { createApp } from 'vue'

export function loadAllPlugins(app: ReturnType<typeof createApp>) {
  const files = require.context('.', true, /\.ts$/)
  files.keys().forEach(key => {
    if (typeof files(key).default === 'function') {
      if (key !== './index.ts') files(key).default(app)
    }
  })
}